<template>
  <div class="mb-6">
    <h1 class="text--secondary">Showcase Your Table Booking Platform</h1>
    <p>
      Generate an iFrame code for your table booking platform and embed it into
      your existing website.
    </p>
    <div class="d-inline-block">
      <v-switch v-model="active" class="mt-0" color="primary" hide-details
        ><template v-slot:label>
          <span class="">Show HTML generator</span></template
        ></v-switch
      >
    </div>
    <div v-if="active" class="frame-gen mb-4">
      <div>
        <v-checkbox
          v-model="horizontal"
          label="Use Horizontal Layout"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="show_calendar"
          :disabled="horizontal"
          label="Use Calendar"
          hide-details
        ></v-checkbox>
      </div>
      <div>
        <v-alert
          v-if="horizontal"
          type="info"
          color="primary"
          colored-border
          border="left"
        >
          Horizontal widget requires <b>two</b> pieces of code embedded on to
          your website. An <b>iframe</b> and <b>styles</b> code. Make sure you
          put <b v-text="`<style>...</style>`"></b> code between your website
          <b v-text="`<head>...</head>`" /> tag.
        </v-alert>
        <div
          v-if="horizontal"
          class="iframe-code mb-2"
          v-clipboard="() => style_code"
          @click="feedback_copied"
        >
          <pre style="white-space: pre-wrap">{{ style_code }}</pre>
          <div class="mask">
            <p class="fade-in" v-if="copied">
              <v-icon class="mr-4" color="white" large>mdi-check</v-icon
              ><span class="mt-3">Copied</span>
            </p>
            <p class="fade-in mb-0" v-else>
              Click to copy this code snippet <br />
              Embed it on your website between
              <b v-text="`<head>...</head>`" /> tag to correctly display book a
              table widget as shown in previews below.
            </p>
          </div>
        </div>
        <div
          class="iframe-code"
          v-clipboard="() => iframe_code"
          @click="feedback_copied"
        >
          <pre style="white-space: pre-wrap">{{ iframe_code }}</pre>
          <div class="mask">
            <p class="fade-in" v-if="copied">
              <v-icon class="mr-4" color="white" large>mdi-check</v-icon
              ><span class="mt-3">Copied</span>
            </p>
            <p class="fade-in mb-0" v-else>
              Click to copy this code snippet <br />
              Embed it on your website to display book a table widget as shown
              in preview below.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="active"
      class="d-flex flex-column justify-center align-center frame-preview"
    >
      <div class="text--secondary text-left full-width">
        <h1>Widget Preview</h1>
        <v-radio-group v-model="preview_type">
          <v-radio label="Mobile Preview" value="mobile"></v-radio>
          <v-radio label="Desktop Preview" value="desktop"></v-radio>
        </v-radio-group>
      </div>
      <iframe
        v-if="preview_type === 'mobile'"
        id="booking-widget"
        style="width: 450px; border-width: 0px"
        :height="mobile_frame_height + 'px'"
        :key="frame_src"
        :src="frame_src"
        title="Order Platform - Iframe"
      ></iframe>
      <iframe
        v-else-if="preview_type === 'desktop'"
        id="booking-widget"
        style="width: 100%; border-width: 0px"
        :height="desktop_frame_height + 'px'"
        :key="frame_src + 'dekstop'"
        :src="frame_src"
        title="Order Platform - Iframe"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "BookATableIframe",
  data() {
    return {
      active: false,
      copied: false,
      horizontal: false,
      show_calendar: true,
      preview_type: "mobile",
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapGetters("BrandStore", ["brand_url"]),
    frame_src() {
      const params = { horizontal: this.horizontal };

      if (!this.horizontal) {
        params.show_calendar = this.show_calendar;
      }
      const encoded_params = Object.entries(params)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");
      return `${this.brand_url}iframe/${this.shop_id}/book-a-table?${encoded_params}`;
    },
    mobile_frame_height() {
      if (this.horizontal) return 300;
      if (this.show_calendar) return 780;
      return 450;
    },
    desktop_frame_height() {
      if (this.horizontal) return 150;
      if (this.show_calendar) return 780;
      return 450;
    },
    iframe_code() {
      return (
        "<iframe\n" +
        `  id="booking-widget"\n` +
        `  style="width: 100%; background: transparent; border-width: 0px; ${
          this.horizontal ? "" : "height: " + this.desktop_frame_height + "px"
        }"\n` +
        `  src="${this.frame_src}"\n` +
        '  title="Book a Table"\n' +
        "></iframe>"
      );
    },
    style_code() {
      return (
        "<style>\n" +
        `#booking-widget {\n` +
        `  height: 300px;\n` +
        `}\n` +
        `@media (min-width: 961px) {\n` +
        `  #booking-widget {\n` +
        `    height: 150px;\n` +
        `  }\n` +
        "}\n" +
        "</style>"
      );
    },
  },
  methods: {
    feedback_copied() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.frame-gen {
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 1fr 3fr;
  }
}
.frame-preview {
  background: white;
  border-radius: 16px;
  padding: 2rem;
}
</style>
